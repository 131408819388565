import {createApp} from 'vue';
import App from './App.vue';
import Amplify from 'aws-amplify';
import {DataStore} from '@aws-amplify/datastore';
import aws_exports from './aws-exports';
import vClickOutside from 'click-outside-vue3';
import router from './router';
import store from './store';
import './assets/css/app.css';
import 'vue-toastification/dist/index.css';
import {applyPolyfills, defineCustomElements} from '@aws-amplify/ui-components/loader';
import {Hub} from 'aws-amplify';
import Toast from 'vue-toastification';
import Breadcrumb from '@/components/Breadcrumb';
import Topbar from '@/components/Topbar';

Amplify.configure(aws_exports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});

Hub.listen('auth', async hubData => {
  const {event} = hubData.payload;
  if (event === 'signOut') {
    await DataStore.clear();
  }
});
const options = {
  // You can set your default options here
};
createApp(App)
  .use(router)
  .use(store)
  .use(Toast, options)
  .use(vClickOutside)
  .component('Breadcrumb', Breadcrumb)
  .component('Topbar', Topbar)
  .mount('#app');
