/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://y25m6kvmovc6zi6jey7rysahqa.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "NodeJSExporter",
            "endpoint": "https://n3fif6kin3.execute-api.ap-southeast-1.amazonaws.com/staging",
            "region": "ap-southeast-1"
        },
        {
            "name": "exportDownload",
            "endpoint": "https://p0ibj7e37k.execute-api.ap-southeast-1.amazonaws.com/staging",
            "region": "ap-southeast-1"
        },
        {
            "name": "AdminQueries",
            "endpoint": "https://5kruaj7yy1.execute-api.ap-southeast-1.amazonaws.com/staging",
            "region": "ap-southeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-1:bd414926-88c4-45f3-b8c0-cbc55e3caea0",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_gmIKcEPo8",
    "aws_user_pools_web_client_id": "4meoqgea6vcvpaom2u0va87cis",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "export-storage-7890987654562789423492800-staging",
    "aws_user_files_s3_bucket_region": "ap-southeast-1",
    "aws_content_delivery_bucket": "whnps-20210527121139-hostingbucket-staging",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://d1tc36t72apa9q.cloudfront.net"
};


export default awsmobile;
